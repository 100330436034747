import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DeviceDetectionService {
  // Testing flags
  private _forceShowMobileBanner: boolean = false; // Change this to false when you're done testing
  private _forcePlatform: "ios" | "android" | null = null; // Set to 'ios', 'android', or null for real detection

  isMobile(): boolean {
    if (this._forceShowMobileBanner) {
      return true;
    }
    const userAgent = navigator.userAgent || navigator.vendor;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    );
  }

  isIOS(): boolean {
    if (this._forcePlatform === "ios") {
      return true;
    }
    if (this._forcePlatform === "android") {
      return false;
    }
    const userAgent = navigator.userAgent || navigator.vendor;
    return /iPhone|iPad|iPod/i.test(userAgent);
  }

  isAndroid(): boolean {
    if (this._forcePlatform === "android") {
      return true;
    }
    if (this._forcePlatform === "ios") {
      return false;
    }
    const userAgent = navigator.userAgent || navigator.vendor;
    return /Android/i.test(userAgent);
  }

  getAppStoreLink(): string {
    if (this.isIOS()) {
      return "https://apps.apple.com/sc/app/mocingbird/id1489280926";
    }
    return "https://play.google.com/store/apps/details?id=com.mocingbird";
  }

  // Method to toggle the banner for testing
  toggleForceMobileBanner() {
    this._forceShowMobileBanner = !this._forceShowMobileBanner;
    return this._forceShowMobileBanner;
  }

  // Method to get current state
  get isForceShowMobileBanner(): boolean {
    return this._forceShowMobileBanner;
  }
}

<nav class="navbar navbar-default navbar-fixed-top" id="public">
  <div class="container nav-container">
    <div class="navbar-header">
      <a class="navbar-brand" href="/">
        <img src="/assets/images/logo.svg" />
      </a>
    </div>
    <ul class="nav navbar-nav navbar-right">
      <li class="d-grid gap-2">
        <button type="button" class="btn btn-lg btn-outline-primary rounded-pill"
          routerLink="/users/sign_in">Login</button>
      </li>
    </ul>
  </div>
</nav>
<div id="home" class="bg-white">
  <div class="container">
    <!-- Download selection -->
    <div class="row row-eq-height hero text-center center-block">
      <div class="col-xs-12 col-sm-12 col-md-12">
        <div class="vertical-align-wrapper">
          <div class="vertival-align mt-20">
            <ng-container *ngIf="download_success_message?.length > 0">
              <app-alert-message [type]="'success'">{{ download_success_message }}</app-alert-message>
            </ng-container>

            <ng-container *ngIf="download_error?.length > 0">
              <app-alert-message [message]="download_error" [type]="'danger'"></app-alert-message>
            </ng-container>

            <h4 class="how-it-works__subtitle">
              Welcome to MOCINGBIRD secure file download utility
            </h4>
            <br />
            <button
              (click)="downloadZipFile()"
              class="btn btn-sm btn-primary"
              data-disable-with="Download"
            >
              Download
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isUserImpersonate == true || isUserImpersonate == 'true'">
  <div id="login-header">
    <div class="d-flex align-items-center">
      <img src="assets/images/banner-alert.png" class="banner-alert" />
      <span class="title">Delegate Access: You’re currently logged in as:</span>
      <span class="title user-name">{{ impersonatingUsername }}</span>
    </div>
    <span class="title logout-btn" (click)="impersonateOut()">Log out</span>
  </div>
</div>

<!-- Banner nav -->
<nav
  aria-label=""
  class="banner-nav"
  id="banner"
  style="min-height: 0px"
  *ngIf="userBanners !== undefined && userBanners !== null && userBanners?.length > 0"
>
  <div id="accordion">
    <div class="banner-container" *ngFor="let userBanner of userBanners; let index = index">
      <div class="banner-title">
        <img src="../../assets/images/banner-alert.png" height="20px" alt="" />
        <span> {{ userBanner?.title }} </span>
        <a
          data-bs-toggle="collapse"
          [attr.href]="'#banner-desc-' + index"
          role="button"
          aria-expanded="false"
          [attr.aria-controls]="'banner-desc-' + index"
        >
          <span>Learn more </span>
        </a>
      </div>
      <div id="banner-desc-{{ index }}" class="collapse" data-bs-parent="#accordion">
        <div class="card-body">
          <div class="banner-desc">
            {{ userBanner?.description }}
          </div>
          <a id="banner-link" [href]="userBanner?.button_url" target="_blank">
            {{ userBanner?.button_text }}
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>

<!-- main nav -->
<nav class="navbar navbar-expand-lg navbar-light">
  <div class="container-fluid">
    <a *ngIf="!isShowOtherLogo" class="navbar-brand" routerLink="/dashboard">
      <img class="logo-big-screen" src="/assets/images/logo.svg" alt="" />
    </a>
    <a *ngIf="isShowOtherLogo" class="navbar-brand" routerLink="/dashboard">
      <img
        alt=""
        class="logo-big-screen"
        height="40"
        width="40"
        src="{{
          providerDetails?.logo_url == 'NA'
            ? '/assets/images/nav/no-preview.png'
            : providerDetails?.logo_url
        }}"
      />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarText"
      aria-controls="navbarText"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0 cursor-pointer">
        <li
          class="nav-item"
          *ngIf="user?.type === 'Administrator'"
          (click)="navigateNavAction('/dashboard/admin')"
        >
          <a
            class="nav-link"
            [class.active]="router.url.includes('dashboard') || router.url.includes('tasks')"
            >Admin Dashboard</a
          >
        </li>
        <li
          class="nav-item"
          *ngIf="user?.type !== 'Administrator'"
          (click)="navigateNavAction('/dashboard')"
        >
          <a
            class="nav-link"
            [class.active]="router.url.includes('dashboard') || router.url.includes('tasks')"
            >Dashboard</a
          >
        </li>
        <li
          class="nav-item"
          *ngIf="userRole == appConstant.ADMIN_ROLE"
          (click)="navigateNavAction('/users')"
        >
          <a class="nav-link" [class.active]="router.url.includes('users')">Users</a>
        </li>
        <li class="nav-item" (click)="navigateNavAction('files/all')">
          <a class="nav-link" [class.active]="router.url.includes('files')">File Cabinet</a>
        </li>
        <li class="nav-item" (click)="learningCenterRedirection()">
          <a class="nav-link">Learning Center</a>
        </li>
        <li class="nav-item" (click)="navigateNavAction('state-required-courses')">
          <a class="nav-link" [class.active]="router.url.includes('state-required-courses')"
            >Browse State Required Courses</a
          >
        </li>
      </ul>
      <div class="dropdown">
        <a
          class="dropdown-toggle"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img id="avatar" [src]="profile_url" class="img-circle" width="40" height="40" alt="" />
          <strong>
            <ng-container *ngIf="type == 'SW'; else showType">
              {{ user?.first_name | titlecase }}
              {{ user?.last_name | titlecase }}
            </ng-container>
            <ng-template #showType>
              {{ this.type }} {{ this.user?.last_name | titlecase }}
            </ng-template>
          </strong>
        </a>
        <div
          class="nav-org-name-subtext"
          *ngIf="user?.current_role?.type == appConstant.ADMIN_ROLE"
        >
          {{ user?.current_role?.organization_name }}
        </div>

        <ul class="dropdown-menu">
          <li class="dropdown-item">
            <a class="font-size-14" (click)="navigateToHistory()"
              ><i class="fa-solid fa-clock-rotate-left"></i> My History</a
            >
          </li>
          <li class="dropdown-item">
            <a class="font-size-14" (click)="navigateNavAction('account/settings')"
              ><i class="fa-solid fa-gear"></i> Account Settings</a
            >
          </li>
          <li class="dropdown-item" (click)="logout()">
            <a class="font-size-14" style="cursor: pointer"
              ><i class="fa-solid fa-right-from-bracket"></i> Logout</a
            >
          </li>
          <li class="dropdown-header font-size-12" *ngIf="user?.roles?.length > 1">ACCOUNTS</li>
          <li
            class="dropdown-item"
            *ngFor="let role of user?.roles"
            [class.active-organization]="user?.current_role?.id == role?.id"
          >
            <a
              class="font-size-14"
              rel="nofollow"
              style="cursor: pointer"
              data-bs-method="post"
              (click)="switchRole(role?.id)"
              >{{ role?.title == 'BehavioralHealth' ? 'SocialWorker (LCSW)' : role?.title }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>

<!-- Impersonate nav -->

<!-- Modal -->
<div
  class="modal fade"
  id="logoutModal"
  tabindex="-1"
  data-bs-backdrop="static"
  aria-labelledby="logoutModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <label class="modal-title logout-modal-title" id="logoutModalLabel">Logout</label>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body font-size-14">
        <h5 class="font-size-14">Are you sure you want to logout ?</h5>
        <ng-container *ngIf="error?.length > 0">
          <app-alert-message [type]="'alert'">{{ error }}</app-alert-message>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="signOut()">YES</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">NO</button>
      </div>
    </div>
  </div>
</div>

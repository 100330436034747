import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
  effect,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { APIEndPoint } from 'src/app/core/constants/api-endpoints';
import { DataPassingService } from 'src/app/support-service/data-passing.service';
import { UserService } from 'src/app/api-service/user.service';
import { RoleService } from 'src/app/support-service/role.service';
import { SessionService } from 'src/app/core/services/session.service';
import { AppConstant } from 'src/app/core/constants/enums';
import { StorageService } from 'src/app/core/store/storage.service';
import { CoreModule } from 'src/app/core/core.module';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { ProfileService } from 'src/app/api-service/profile.service';
import { SignalService } from 'src/app/core/services/signal.service';

declare var $: any;

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CoreModule, TitleCasePipe, CommonModule],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnChanges {
  @Output() toggle = new EventEmitter<string>();
  @Input() isUserImpersonate: any;
  appConstant = AppConstant;
  subscription!: Subscription;
  username!: string;
  error: any = '';
  user: any;
  type!: string;
  impersonatingUsername: any;
  isShowOtherLogo: boolean = false;
  providerDetails: any;
  profile_url: any;
  userBanners: any = [];
  userRole: any;
  navbarCollapsed = true;
  isUserType: boolean = false;

  private storage = inject(StorageService);
  private message = inject(DataPassingService);
  private roleService = inject(RoleService);
  private userService = inject(UserService);
  private sessionService = inject(SessionService);
  private signalService = inject(SignalService);
  router = inject(Router);

  constructor() {
    effect(() => {
      this.isUserType = this.signalService.userType();
      this.user = this.storage?.getUsersData;
      this.getUserType(this.user);
    });
  }

  toggleNavbarCollapsing() {
    this.navbarCollapsed = !this.navbarCollapsed;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['isUserImpersonate']) {
      this.user = this.storage?.getUsersData;
      this.userRole = this.storage?.getUserRole;
      this.isUserImpersonate = this.storage?.getImpersonateUser;
      this.impersonatingUsername = this.storage?.getImpersonatingUsername || 'NA';
    }
  }

  ngOnInit() {
    this.user = this.storage?.getUsersData;
    if (this.user?.roles) {
      this.user.roles.sort((a: any, b: any) => a.title.localeCompare(b.title));
    }
    this.userRole = this.storage?.getUserRole;
    if (this.userRole == AppConstant.ADMIN_ROLE) {
      this.roleService.changeLoginAs(true);
    }
    if (this.user === null || this.user === undefined) {
      this.router.navigate(['users/sign_in']);
      return;
    }
    this.roleService.providerObj.subscribe((obj: any) => {
      this.providerDetails = obj;
      if (this.providerDetails.role == AppConstant.ADMIN_ROLE) {
        this.isShowOtherLogo = true;
      } else {
        this.isShowOtherLogo = false;
      }
    });
    this.getBannerList();
    this.isUserImpersonate = this.storage?.getImpersonateUser;
    this.impersonatingUsername = this.storage?.getImpersonatingUsername || 'NA';

    this.roleService.userNameShow.subscribe((name: any) => (this.username = name));
    this.roleService.user_logo_url.subscribe((logo: string) => {
      this.profile_url = logo;
    });
  }

  getBannerList() {
    this.userService.getBannerList().subscribe({
      next: (res: any) => {
        if (res && res.banners && res.banners.length > 0) {
          this.userBanners = res.banners;
        } else {
          this.userBanners = null;
        }
      },
      error: (error: any) => {
        this.sessionService.checkUserSession(error);
      },
    });
  }

  toggleMe() {
    this.toggle.emit('toggle');
  }

  signOut() {
    this.storage?.setToken('');
    this.storage?.setUserRole(AppConstant.PHYSICIAN_ROLE);
    this.roleService.changeRole(AppConstant.PHYSICIAN_ROLE);
    this.storage?.setUserNameShow('');
    this.storage?.setUsername('');
    $('#logoutModal').modal('hide');
    this.storage?.clearStorage();
    this.router.navigate(['/']);
    this.sessionService.isSessionActive$.next(false);
    return;
  }

  logout() {
    $('#logoutModal').modal('show');
  }

  switchRole(id: any) {
    this.storage?.setSwitchRoleId(id);
    this.userService.switchRole(id).subscribe((res: any) => {
      let response: any = res;
      if (response.success) {
        let obj = {
          logo_url: '',
          organization_name: '',
          role: '',
        };
        if (response.current_role.logo_url != undefined) {
          obj = {
            logo_url: response.current_role.logo_url,
            organization_name: response.current_role.organization_name,
            role: response.current_role.type,
          };
          this.roleService.changeProviderObj(obj);
          this.storage?.setProviderDetails(obj);
        }

        this.storage?.setToken(response.token);
        this.roleService.changeLoginAs(false);
        if (response.current_role.type == AppConstant.ADMIN_ROLE) {
          this.roleService.changeLoginAs(true);
          this.roleService.changeRole(AppConstant.ADMIN_ROLE);
          this.storage?.setUserRole(AppConstant.ADMIN_ROLE);
        }
        if (
          response.current_role.type == AppConstant.MEMBER_ROLE &&
          !response.current_role.leader
        ) {
          this.roleService.changeRole(AppConstant.MEMBER_ROLE);
          this.storage?.setUserRole(AppConstant.MEMBER_ROLE);
        }
        if (
          response.current_role.type == AppConstant.PHYSICIAN_ROLE &&
          !response.current_role.leader
        ) {
          this.roleService.changeRole(AppConstant.PHYSICIAN_ROLE);
          this.storage?.setUserRole(AppConstant.PHYSICIAN_ROLE);
        }
        if (response.current_role.type == AppConstant.MEMBER_ROLE && response.current_role.leader) {
          this.roleService.changeRole(AppConstant.LEADER_ROLE);
          this.storage?.setUserRole(AppConstant.LEADER_ROLE);
        }
        for (let i = 0; i < response.roles.length; i++) {
          if (response.roles[i].id == id) {
            this.roleService.changeUserName(response.username + ' ' + response.roles[i].title);
            this.username = response.username + ' ' + response.roles[i].title;
            this.storage?.setUserNameShow(response.username + ' ' + response.roles[i].title);
          }
        }
        if (!this.roleService.checkRouteAllow()) {
          this.router.navigate(['dashboard']);
        }
        this.storage?.setUsersData(response);
        this.user = this.storage?.getUsersData;
      }
      this.userRole = this.storage?.getUserRole;
      if (this.userRole == AppConstant.ADMIN_ROLE) {
        this.roleService.changeLoginAs(true);
        if (this.router.url === '/dashboard/admin') {
          this.message.setOrganizationData(true);
        } else {
          this.router.navigate(['dashboard/admin']);
        }
      } else {
        this.router.navigate(['dashboard']);
      }
    });
    return;
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  impersonateOut() {
    this.isUserImpersonate = false;
    this.router.navigate([AppConstant.IMPERSONATION_SIGN_OUT_PATH]);
  }

  getUserType(userType: any) {
    switch (userType?.type?.replace(' ', '')) {
      case AppConstant.PHYSICIAN:
        this.type = 'Dr';
        break;
      case AppConstant.DOCTOR_OF_OSTEOPATHY:
        this.type = 'Dr';
        break;
      default:
        this.type = userType?.credential;
        break;
    }
  }

  splitBannerTitle(title: string) {
    return title.substring(0, 42) + '..';
  }

  navigateNavAction(currentPath: any) {
    this.router.navigate([currentPath]);
  }

  learningCenterRedirection() {
    let token = `${this.storage?.getToken}`;
    window.open(
      APIEndPoint.ENDPOINT_URL + '/sso/web?token=' + token + '&target=dashboard',
      '_self'
    );
  }

  navigateToHistory() {
    this.router.navigate(['account/history']);
  }
}

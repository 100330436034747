import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/browser';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
dsn: environment.SENTRY_DSN_URL,
  integrations: [
    new BrowserTracing({
      tracingOrigins: environment.ALLOWED_TRACING_ORIGINS,
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  tracesSampleRate: 1.0,

  beforeSend(event: any, hint: any) {
    const isNonErrorException =
        event?.exception?.values[0]?.value?.startsWith('Non-Error exception captured with keys:') ||
        hint?.originalException?.message?.startsWith('Non-Error exception captured with keys:');
    if (isNonErrorException) {
      return null;
    }
    return event;
  }
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success: any) => console.log(`Bootstrap success`))
  .catch((err: any) => console.error(err));
  <div class="container-fluid">
  <div class="pt-5 px-4">
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <div class="form-group">
          <label for="state">State</label>
          <ng-select
            class="state-licence-dropdown"
            [items]="us_state_list"
            bindLabel="abbreviation"
            bindValue="abbreviation"
            [(ngModel)]="state"
            maxlength="60"
          >
          </ng-select>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="form-group">
          <label for="clinician">Clinician</label>
          <ng-select
            class="state-licence-dropdown"
            [items]="clinicianList"
            bindLabel="item"
            bindValue="item"
            [(ngModel)]="clinician"
            maxlength="60"
          >
          </ng-select>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="form-group">
          <label for="provider">Content Provider</label>
          <ng-select
            class="state-licence-dropdown"
            [items]="contentList"
            bindLabel="item"
            bindValue="item"
            [(ngModel)]="contentProviders"
            maxlength="60"
          >
          </ng-select>
        </div>
      </div>
    </div>
</div>

<div class="d-flex flex-column align-items-center py-5"> 
  <div class="fw-bold font-18">Browse State Required Courses</div>
  <div>
   <div class="text-center">
    We are actively working to update and add additional course options to this table.
   </div>
   <div class="text-center">
    If you cannot find what you are looking for, please visit our learning center to view all course options.
   </div>
 </div>
</div>
<div class="table-container">
<table class="table border-none file-cabinet-table">
  <thead>
    <tr>
      <th scope="col" class="text-start">State</th>
      <th scope="col" class="text-start">Clinician</th>
      <th scope="col" class="text-start">Category</th>
      <th scope="col" class="text-start">Content Provider</th>
      <th scope="col" class="text-start">Title </th>
      <th scope="col" class="text-start">Credits </th>
      <th scope="col" class="text-start">Price </th>
      <th scope="col" class="text-start">Applicable States</th>
    </tr>
  </thead>
  <tbody>
    <tr class="file-cabinet-row" *ngFor="let course of courses | stateCoursesFilter: { state, clinician: clinician, contentProviders: contentProviders}; let i = index">
      <td class="border-white-space text-start">{{course?.state}}</td>
      <td class="text-start">{{ course?.clinician }}</td>
      <td class="w-15 text-start">{{ course?.category }}</td>
      <td [title]="course?.content_provider" class="text-start">{{course?.content_provider}}</td>
      <td [title]="stripHTML(course?.title)" class="text-decoration-underline truncated-link text-start" [innerHTML]="course?.title">
      </td>
      <td class="text-start"><ng-container *ngFor="let credit of course?.formattedCredits">
        {{ credit }}<br />
      </ng-container></td>
      <td class="text-start">{{ course?.prices }}</td>
      <td class="wrap-text text-start">{{ course?.applicable_states?.join(', ') || '' }}</td>
    </tr>
  </tbody>
</table>
</div>

<div class="col-md-12" *ngIf="(courses | stateCoursesFilter: { state, clinician: clinician, contentProviders: contentProviders }).length === 0">
  <div class="text-center alert alert-warning">
    <strong>No courses available for the selected filters:</strong><br />
    <span *ngIf="state">State: <strong>{{ state }}</strong></span>
    <span *ngIf="clinician">, Clinician: <strong>{{ clinician }}</strong></span>
    <span *ngIf="contentProviders">, Content Provider: <strong>{{ contentProviders }}</strong></span>.
  </div>
</div>
</div>


import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NgHttpLoaderModule } from "ng-http-loader";
import { CertificateService } from "./api-service/certificate.service";
import { ProfileService } from "./api-service/profile.service";
import { UserService } from "./api-service/user.service";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { NgxWebstorageModule } from "ngx-webstorage";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { httpInterceptor } from "./core/interceptor/http.interceptor";
import { GlobalErrorHandlerService } from "./core/services/global-error-handler.service";
import { SessionService } from "./core/services/session.service";
import { SharedModule } from "./modules/shared-component/shared.module";
import { NavbarComponent } from "./shared-resources/navbar/navbar.component";
import { DataPassingService } from "./support-service/data-passing.service";
import { RoleService } from "./support-service/role.service";
import { ZipDownloadComponent } from "./zip-download/zip-download.component";
import { MobileBannerComponent } from './core/components/mobile-banner/mobile-banner.component';

@NgModule({
  declarations: [AppComponent, ZipDownloadComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgHttpLoaderModule.forRoot(),
    NgxWebstorageModule.forRoot(),
    SharedModule,
    BrowserAnimationsModule,
    CoreModule,
    NavbarComponent,
    MobileBannerComponent
  ],
  providers: [
    UserService,
    DataPassingService,
    SessionService,
    provideHttpClient(withInterceptors([httpInterceptor])),
    CertificateService,
    ProfileService,
    RoleService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { StateRequiredCoursesComponent } from "./modules/state-required-courses/state-required-courses.component";
import { AlwaysAuthGuard } from "./core/guards/auth.guard";
import { ZipDownloadComponent } from "./zip-download/zip-download.component";
import { LearningCenterComponent } from "./modules/learning-center/learning-center.component";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./modules/auth/auth.module").then((m) => m.AuthModule),
  },
  { path: "secure_file_download/:id", component: ZipDownloadComponent },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "impersonate-user/:status",
    loadComponent: () =>
      import("./modules/impersonation/impersonation.component").then(
        (m) => m.ImpersonationComponent
      ),
  },
  {
    path: "files",
    loadChildren: () =>
      import("./modules/file-cabinet/file-cabinet.module").then(
        (m) => m.FileCabinetModule
      ),
  },
  {
    path: "users",
    loadChildren: () =>
      import("./modules/users/users.module").then((m) => m.UsersModule),
  },

  {
    path: "onboarding",
    loadChildren: () =>
      import("./modules/onboarding/onboarding.module").then(
        (m) => m.OnboardingModule
      ),
  },
  {
    path: "account",
    loadChildren: () =>
      import("./modules/account/account.module").then((m) => m.AccountModule),
  },
  {
    path: "",
    component: LearningCenterComponent,
    canActivate: [AlwaysAuthGuard],
  },
  {
    path: "state-required-courses",
    component: StateRequiredCoursesComponent,
    canActivate: [AlwaysAuthGuard],
  },
  {
    path: "categorization",
    loadChildren: () =>
      import("./modules/file-categorization/file-categorization.module").then(
        (m) => m.FileCategorizationModule
      ),
  },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-mobile-banner",
  standalone: true,
  imports: [CommonModule],
  template: `
    <div *ngIf="show" class="mobile-banner">
      <div class="banner-body">
        <div class="banner-content">
          <span class="banner-text">{{ message }}</span>
          <a [href]="appStoreLink" target="_blank" class="btn btn-primary">
            Get the App
          </a>
        </div>
        <span class="banner-close-btn">
          <button
            class="btn-close"
            (click)="dismiss()"
            aria-label="Close"
          ></button>
        </span>
      </div>
    </div>
  `,
  styles: [
    `
      .mobile-banner {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        background: #f8f9fa;
        border-top: 1px solid #dee2e6;
      }

      .banner-body {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 25px;
        position: relative;
        gap: 8px;
      }

      .banner-content {
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;
      }

      @media (max-width: 615px) {
        .banner-content {
          flex-direction: column;
          text-align: center;
        }
        .banner-close-btn {
          transform: translateY(-50%);
          position: absolute;
          top: 0;
          right: 8px;
          background: #f8f9fa;
          border: 1px solid #dee2e6;
          border-radius: 50%;
          padding: 4px 4px 2px 4px;
        }
      }
    `,
  ],
})
export class MobileBannerComponent {
  @Input() message: string = "Get a better experience with our mobile app!";
  @Input() appStoreLink: string = "https://apps.apple.com/your-app";
  show: boolean = true;

  dismiss() {
    this.show = false;
  }
}

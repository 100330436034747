import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stateCoursesFilter',
  standalone: true
})
export class StateCoursesFilterPipe implements PipeTransform {

  transform(courses: any[], filters: { state: string; clinician: string; contentProviders: string}): any[] {
    if (!courses || !filters) return courses;

    const { state, clinician, contentProviders } = filters;

    return courses.filter((course) => {
      const matchesState = state ? course.state === state : true;
      const matchesClinician = clinician ? course.clinician === clinician : true;
      const matchesProvider = contentProviders ? course.content_provider === contentProviders : true;

      return matchesState && matchesClinician && matchesProvider;
    });
  }
}

import { Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { StateLicenseService } from "../../api-service/state-license.service";
import { SharedModule } from "../shared-component/shared.module";
import { CommonModule } from "@angular/common";
import { StateCoursesFilterPipe } from "src/app/core/pipes/state-courses-filter.pipe";
import { SessionService } from "src/app/core/services/session.service";

@Component({
  selector: "app-state-required-courses",
  templateUrl: "./state-required-courses.component.html",
  styleUrls: ["./state-required-courses.component.scss"],
  standalone: true,
  imports: [SharedModule, FormsModule, CommonModule, StateCoursesFilterPipe],
  providers: [SessionService],
})
export class StateRequiredCoursesComponent implements OnInit {
  us_state_list: any;
  state: any;
  clinicianList: any;
  courses: any[] = [];
  clinician: any;
  contentList: any;
  contentProviders: any;

  constructor(
    private stateLicenseService: StateLicenseService,
    private sessionService: SessionService
  ) {}

  ngOnInit() {
    this.getStateTableData();
    this.getClinicianList();
    this.getContentProviderList();
    this.stateLicenseService.getStates().subscribe({
      next: (res: any) => {
        this.us_state_list = res?.states;
      },
      error: (err: any) => {
        this.sessionService.checkUserSession(err);
      },
    });
  }

  getStateTableData() {
    this.stateLicenseService.getStateRequiredCourses().subscribe({
      next: (res: any) => {
        this.courses = res?.courses;
        this.courses.forEach((course: any) => {
          if (course?.credits) {
            course.formattedCredits = course?.credits?.split('\r\n'); 
          } else {
            course.formattedCredits = [];
          }
        });
      },
      error: (err: any) => {
        this.sessionService.checkUserSession(err);
      },
    });
  }
  
  
  getClinicianList() {
    this.stateLicenseService.getClinicianList().subscribe({
      next: (res: any) => {
        this.clinicianList = res?.clinicians;
      },
      error: (err: any) => {
        this.sessionService.checkUserSession(err);
      },
    });
  }

  getContentProviderList() {
    this.stateLicenseService.getContentProviderList().subscribe({
      next: (res: any) => {
        this.contentList = res?.content_providers;
      },
      error: (err: any) => {
        this.sessionService.checkUserSession(err);
      },
    });
  }

  stripHTML(html: string): string {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
  }
}
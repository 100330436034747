import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { saveAs } from "file-saver";
import { MessageConstant } from "src/app/core/constants/messages";
import { UserService } from '../api-service/user.service';

@Component({
  selector: 'app-zip-download',
  standalone:false,
  templateUrl: './zip-download.component.html',
  styleUrls: ['./zip-download.component.scss']
})

export class ZipDownloadComponent implements OnInit {
  download_error:any = [];
  download_success_message: any = [];
  is_passCode: boolean = true;
  pass_code: any;
  file_id: any;

  constructor(private router: Router,private userService: UserService) {

  }

  ngOnInit(): void {
    this.file_id = this.router.routerState.snapshot.url.split("/")[2];
  }

  checkPassCode(pass_code:any): void {
    this.is_passCode = false;
    if (pass_code == "") {
      this.is_passCode = true;
    }
  }

  downloadZipFile(): void {
    this.download_error = [];
    this.download_success_message = "";
    this.userService.downloadZipFile(this.file_id).subscribe({
      next: (res) => {
        let response: any = res;

        this.pass_code = null;
        saveAs(res, "zip file");
        this.download_success_message = MessageConstant.SUCCESS_ZIP_DOWNLOADED;
      },
      error: (err) => {
        this.download_error = [];
        let error: any = err;

        if (error?.error?.status == 500) {
          this.download_error.push(MessageConstant.INTERNAL_SERVER);
        }
        if (error?.status == 404) {
          let mgs =
          MessageConstant.FILE_NO_LONGER_AVAILABLE;
          this.download_error.push(mgs);
        }
        if (error?.status == 400) {
          let mgs =
          MessageConstant.INVALID_PASS_CODE;
          this.download_error.push(mgs);
        }
      }
  });
  }
}

<ng-container *ngIf="isLoggedIn && !isCategorized">
  <app-navbar [isUserImpersonate]="isUserImpersonate"></app-navbar>
</ng-container>
<router-outlet></router-outlet>
<ng-http-loader [backgroundColor]="'#485cc7'"></ng-http-loader>
<app-mobile-banner
  *ngIf="isMobileDevice"
  message="Get a better experience with our mobile app!"
  [appStoreLink]="deviceDetectionService.getAppStoreLink()"
>
</app-mobile-banner>

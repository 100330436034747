import {
  HttpErrorResponse,
  HttpEvent,
  HttpInterceptorFn,
  HttpRequest,
} from "@angular/common/http";
import { inject } from "@angular/core";
import { catchError, Observable, of, throwError } from "rxjs";
import { Router } from "@angular/router";
import { StorageService } from "../store/storage.service";
import { SessionService } from "../services/session.service";
import { AppConstant } from "../constants/enums";

// Interceptor code using HttpInterceptorFn
export const httpInterceptor: HttpInterceptorFn = (
  request: HttpRequest<any>,
  next: (req: HttpRequest<any>) => Observable<HttpEvent<any>>
): Observable<HttpEvent<any>> => {
  const storageService: StorageService = inject(StorageService);
  const sessionService: SessionService = inject(SessionService);
  const router: Router = inject(Router);
  const headers = request.headers.set("Content-Type", "application/json");
  const data = storageService?.getUsersData;
  const debugMode = storageService?.getDebugMode;

  let token = null;
  if (data !== null) {
    token = data?.token;
  }

  if (headers?.get("noToken") === "noToken") {
    return next(request);
  }

  if (headers?.get("impersonateToken") === "impersonateToken") {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${storageService.getImpersonateToken}`,
      },
    });
    return next(request).pipe(
      catchError((err) =>
        handleAuthError(
          err,
          () => storageService.clearStorage(),
          () => sessionService.isSessionActive$.next(false),
          (path) => router.navigate([path])
        )
      )
    );
  }

  if (debugMode !== undefined && debugMode === "true") {
    /** Debug mode. */
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ` + token,
        as: AppConstant.SUPER_ADMIN,
      },
    });
  } else {
    /** Normal mode. */
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ` + token,
      },
    });
  }
  return next(request).pipe(
    catchError((err) =>
      handleAuthError(
        err,
        () => storageService.clearStorage(),
        () => sessionService.isSessionActive$.next(false),
        (path) => router.navigate([path])
      )
    )
  );
};

// Functional approach to handle authentication errors
export const handleAuthError = (
  err: HttpErrorResponse,
  clearStorage: () => void,
  deactivateSession: () => void,
  navigateTo: (path: string) => void
): Observable<never> => {
  return err.status === 401
    ? handleUnauthorizedError(clearStorage, deactivateSession, navigateTo)
    : throwError(() => err);
};

// Helper function to handle 401 Unauthorized error
export const handleUnauthorizedError = (
  clearStorage: () => void,
  deactivateSession: () => void,
  navigateTo: (path: string) => void
): Observable<never> => {
  clearStorage();
  deactivateSession();
  navigateTo("/");
  return of();
};

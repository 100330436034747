import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIEndPoint } from 'src/app/core/constants/api-endpoints';
import { StorageService } from 'src/app/core/store/storage.service';

@Component({
  selector: 'app-learning-center',
  standalone: true,
  imports: [],
  templateUrl: './learning-center.component.html',
  styleUrl: './learning-center.component.scss'
})
export class LearningCenterComponent implements OnInit {
  destinationString!: string;

  router = inject(Router);
  route = inject(ActivatedRoute);
  storage = inject(StorageService);

  constructor () {
    this.route.queryParams.subscribe((param) => {
      if (param['destination']) {
        this.destinationString = param['destination'];
      }
    });
  }

  ngOnInit() {
    if (this.storage?.getIsDirectPageLink === 'true') {
      let navigationURL:any = this.storage?.getRedirectPageLink;
      this.storage?.removeLocalStorageItem('isDirectPageLink');
      this.storage?.removeLocalStorageItem('redirectPageLink');
      if (navigationURL?.includes('/learning_center?destination=')) {
        let token = `${this.storage?.getToken}`;
        let destinationString = navigationURL.split('?destination=')[1];
        window.open(
          APIEndPoint.ENDPOINT_URL +
            '/sso/web?destination=' +
            destinationString +
            '&token=' +
            token,
          '_self'
        );
      }
    } else {
      let token = `${this.storage?.getToken}`;
      if (token !== null && token !== undefined && token !== '') {
        window.open(
          APIEndPoint.ENDPOINT_URL +
            '/sso/web?destination=course-catalog-list&token=' +
            token,
          '_self'
        );
      } else {
        this.storage?.setIsDirectPageLink('true');
        let redirectionLink = window.location.href.replace(
          window.location.origin,
          ''
        );
        this.storage?.setRedirectPageLink(redirectionLink);
        this.router.navigate(['users/sign_in']);
      }
    }
  }
}

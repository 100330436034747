import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TraceClassDecorator } from '@sentry/angular';
import { AppConstant } from './core/constants/enums';
import { StorageService } from './core/store/storage.service';
import { SessionService } from './core/services/session.service';
import { FileCategorizationService } from './modules/file-categorization/services/file-categorization.service';
import { DeviceDetectionService } from './core/services/device-detection.service';
declare var gtag: any;

@Component({
  selector: 'app-root',
  standalone: false,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
@TraceClassDecorator()
export class AppComponent implements OnInit {
  isLoggedIn: boolean = false;
  isUserImpersonate: any;
  isCategorized: boolean = false;
  isMobileDevice: boolean = false;

  constructor(
    private storage: StorageService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService,
    public deviceDetectionService: DeviceDetectionService,
    private fileCategorizationService: FileCategorizationService
  ) {
    let pathParamList = window.location.pathname.split('/');
    /** Debug mode. */
    this.activatedRoute.queryParams.subscribe((queryParamList: any) => {
      if (Object.keys(queryParamList).includes('as')) {
        if (queryParamList.as === AppConstant.SUPER_ADMIN) {
          this.storage?.setDebugMode('true');
          this.storage?.setIsSuperAdmin('true');
        } else if (queryParamList.as === AppConstant.USER) {
          this.storage?.removeLocalStorageItem(AppConstant.DEBUG_MODE);
          this.storage?.removeLocalStorageItem(AppConstant.IS_SUPER_ADMIN);
        }
      }
    });
    if (
      pathParamList[1] === 'express' &&
      pathParamList[2] !== null &&
      pathParamList[2] !== undefined
    ) {
      this.superAdminLogin(pathParamList);
    }
  }

  ngOnInit(): void {
    this.sessionService.isLoggedIn.subscribe((isNavbarVisible: boolean) => {
      this.isLoggedIn = isNavbarVisible;
      this.isUserImpersonate = this.storage?.getImpersonateUser;
    });
    this.updateCategorizedStatus();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateCategorizedStatus();
      }
    });
    this.isMobileDevice = this.deviceDetectionService.isMobile();
  }

  updateCategorizedStatus() {
    this.isCategorized = this.router.url.includes('categorization');
  }

  superAdminLogin(pathParamList: any[]) {
    let isSuperAdminLogin = false;
    let decodedResponse: any = JSON.parse(atob(pathParamList[2]));
    this.storage?.clearStorage();
    const userType = decodedResponse.type;
    let role: string | undefined = undefined;

    if (typeof decodedResponse?.current_role === 'object') {
      role = decodedResponse.current_role?.type ?? decodedResponse.role;
    } else if (typeof decodedResponse?.role === 'string') {
      role = decodedResponse.role;
    }

    if (role === AppConstant.REPRESENTATIVE || role === AppConstant.ADMIN_ROLE) {
      this.storage?.setUserRole(role);
    }

    this.storage.setUsersData({
      first_name: decodedResponse.first_name,
      impersonated: decodedResponse.impersonated,
      last_name: decodedResponse.last_name,
      member_organizations: decodedResponse.member_organizations,
      profile_image: decodedResponse.profile_image,
      roles: decodedResponse.roles,
      subscription_details: decodedResponse.subscription_details,
      success: decodedResponse.success,
      token: decodedResponse.token,
      type: decodedResponse.type,
      username: decodedResponse.username,
      board_certification: decodedResponse?.board_certification,
      current_role: decodedResponse?.current_role,
      credential: decodedResponse?.credential,
    });
    if (pathParamList.includes(AppConstant.SUPER_ADMIN)) {
      this.storage?.setIsSuperAdmin('true');
      isSuperAdminLogin = true;
    } else {
      this.storage?.setIsSuperAdmin('false');
      isSuperAdminLogin = false;
    }
    this.sendGTMExtraData(
      decodedResponse?.type,
      decodedResponse?.current_role?.organization_name,
      isSuperAdminLogin
    );
    this.storage?.setToken(decodedResponse.token);

    if (role === AppConstant.REPRESENTATIVE) {
      this.router.navigate(['categorization/file-categorization'], {
        queryParams: { id: decodedResponse.categorization_id },
      });
    } else if (role === AppConstant.ADMIN_ROLE || userType === AppConstant.ADMIN_USER) {
      this.router.navigate(['dashboard/admin']);
    } else {
      this.router.navigate(['dashboard']);
    }
  }

  sendGTMExtraData(userType: string, organization_name: string | undefined, isSuperAdmin: boolean) {
    if (organization_name) {
      gtag('set', 'user_properties', {
        user_type: userType,
        organization: organization_name,
        login_as: isSuperAdmin,
      });
    } else {
      gtag('set', 'user_properties', {
        user_type: userType,
        login_as: isSuperAdmin,
      });
    }
  }
}
